<template>
  <div>
    <RightWindow :title="title" @closedWindow="closedWindow">
        <div class="center_b_2">
          <ul class="ultab">
            <li class='hover'><a href="javascript:void(0)">详细信息</a></li>
          </ul>
        </div>
        <div class="center_b_3">
          <ul>
            <li class="up"><span>员工工号：</span><input type="text" v-model="form.userCode" maxlength="30"/></li>
            <li class="up"><span class="import">员工姓名：</span><input type="text" v-model="form.xingming" maxlength="5"/></li>
            <li class="up"><span class="import">员工账号：</span><input type="text" v-model="form.userName" @blur="checkUser" :disabled="!MmsData.new"><i :class="userErrClass" :title="userErr"></i></li>
            <li class="up" v-if="MmsData.new"><span>登录密码：</span>员工密码初始默认123456</li>
            <li class="up"><span class="import">手机号码：</span><input type="text" v-model="form.phone" maxlength="11" ></li>
            <li class="up"><span class="import">属于门店：</span>
              <select v-model="form.fenpeimendianId" @change="setStoreName" disabled>
                <option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option>
              </select>
            </li>
            <li class="up"><span class="import">员工级别：</span>
              <select v-model="form.jibieId" @change="setShareName">
                <option :value="item.id" v-for="item in shareList" :key="item.id">{{item.shareName}}</option>
              </select>
            </li>
            <li class="up"><span class="import">员工底薪：</span><input type="number" v-model="form.dixin"></li>
            <li class="up"><span class="import">员工性别：</span><label><input type="radio" value="男" v-model="form.xingbie"/>男</label><label><input type="radio" value="女" v-model="form.xingbie"/>女</label></li>
            <li class="up"><span>员工生日：</span><input type="date" v-model="form.shengri"/></li>
            <li class="up"><span>电子邮箱：</span><input type="text" v-model="form.dianziyouxiang"/></li>
            <li class="up"><span>备注信息：</span><textarea v-model="form.info"></textarea></li>
          </ul>
          <div class="center_b_4">
            <button class="settab" @click="subData" :disabled="onsub">保存</button>
            <button class="remole" @click="closedWindow">取消</button>
          </div>
        </div>
    </RightWindow>
  </div>

</template>

<script>
import axios from '@/http/axios'
import {deepCopy,testStr} from '@/utils/util'
import moment from 'moment'
export default {
  name: 'PSinfo',
  props:{
    MmsData:Object,
    title:String,
  },
  watch:{
    'MmsData':{
      handler(newD){
        if(newD.new){
          this.form=deepCopy(this.defform)
        }else{
          this.form=deepCopy(newD.data)
          if(this.form.shengri) this.form.shengri=moment(this.form.shengri).format("YYYY-MM-DD")
          
        }
      },
      deep:true
    }
  },
  data() {
    return {
      onsub:false,
      userErrClass:'',
      userErr:'',
      storeList:[],
      shareList:[],
      form:{
        userName:'',
        fenpeimendianId:this.$store.state.User.fenpeimendianId,
        fenpeimendianName:this.$store.state.User.fenpeimendianName,
        phone:'',
        dixin:0,
        xingbie:'男',
        xingming:'',
        shengri:'',
        dianziyouxiang:'',
        info:'',
        jibieId:'',
        jibieName:'',
      },
      defform:{
        userName:'',
        fenpeimendianId:this.$store.state.User.fenpeimendianId,
        fenpeimendianName:this.$store.state.User.fenpeimendianName,
        phone:'',
        dixin:0,
        xingbie:'男',
        xingming:'',
        shengri:'',
        dianziyouxiang:'',
        info:'',
        jibieId:'',
        jibieName:'',
      }
    }
  },
  created() {
    this.init()
  },
  components: {},
  mounted() {},
  methods: {
    init(){
      this.getStore()
      this.getShare()
      if(!this.MmsData.new){
        this.form=deepCopy(this.MmsData.data)
        if(this.form.shengri) this.form.shengri= moment(this.form.shengri).format("YYYY-MM-DD")
      }
    },
    checkUser(){
      if(this.MmsData.new&&this.form.userName){
        if(this.form.userName.length>=6&&this.form.userName.length<=20){
          axios.post('/user/checkuser',{userName:this.form.userName}).then((res) => {
            if(res.code===5000){
              if(res.data){
                this.onsub=false
                this.userErrClass="icon-check"
                this.userErr='可以注册！'
              }else{
                this.onsub=true
                this.userErrClass="icon-slash"
                this.userErr='账号已经被注册！'
                this.$Mesg({content: `“${this.form.userName}”已经被注册！`,errOrSuc: 'err'})
              }
            }else{
              this.$Mesg({content: res.msg,errOrSuc: 'err'})
            }
          })
        }else{
          this.onsub=true
          this.userErrClass="icon-slash"
          this.userErr='账号应该大于等于6位小于等于20位'
          this.$Mesg({content: `账号应该大于等于6位小于等于20位`,errOrSuc: 'err'})
        }
      }
    },
    delXunhuan(index){
      this.cishuList.splice(index,1)
    },
    addXiangMu(){
      this.cishuList.push(deepCopy(this.cishuObj))
    },
    getShare(){
      axios.post('/share/list').then((res) => {
        if(res.code===5000){
          this.shareList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    getStore(){
      axios.post('/store/list').then((res) => {
        if(res.code===5000){
          this.storeList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    setStoreName(){
      let store=this.storeList.find(e=>e.id==this.form.fenpeimendianId)
      if(store) this.form.fenpeimendianName=store.storeName
    },
    setShareName(){
      let share=this.shareList.find(e=>e.id==this.form.jibieId)
      if(share) this.form.jibieName=share.shareName
    },
    closedWindow(){
      this.$emit('closedWindow')
    },
    subData(){
      if(testStr(this.form.userName)){
        this.$Mesg({
          content: '员工账号不能为空',
          errOrSuc: 'war',
        })
        return
      }
      if(testStr(this.form.fenpeimendianId)){
        this.$Mesg({
          content: '请为该员工分配门店',
          errOrSuc: 'war',
        })
        return
      }
      if(testStr(this.form.phone)){
        this.$Mesg({
          content: '手机号码不能为空！',
          errOrSuc: 'war',
        })
        return
      }
      if(testStr(this.form.xingming)){
        this.$Mesg({
          content: '姓名不能为空！',
          errOrSuc: 'war',
        })
        return
      }
      if(testStr(this.form.jibieId)){
        this.$Mesg({
          content: '请为该员工分配级别',
          errOrSuc: 'war',
        })
        return
      }
      if(!this.form.shengri) this.form.shengri=null
      axios.post(this.MmsData.new?'/emp/add':'/emp/edit',this.form).then((res) => {
        if(res.code===5000){
          this.$Mesg({content: this.MmsData.new?'新增员工成功！':'修改员工成功！'})
          this.$parent.getData(1)
          this.closedWindow()
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
  },
}
</script>
